<template>
  <v-card>
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headers"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Açık Bütçe Giderleri"
            icon="mdi-cash-remove"
            @clear-filters="clearFilters()"
            @click:edit="handleEditClick"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          >
            <template slot="buttons:prepend">
              <rs-select-budget
                class="float-end me-2"
                style="max-width: 150px"
                label="Bütçe Seçin"
                @change="handleBudgetChange"
                v-model="selectedBudget"
                dense
                is-active
                select-first
              />
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />

          <tr v-if="!selectedBudget">
            <td :colspan="headers.length" class="text-center">
              Listeyi görebilmek için bir bütçe seçin.
            </td>
          </tr>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <a href="#" @click.prevent="handleEditClick(item)">
            <i class="menu-icon mdi mdi-pencil"></i>
          </a>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.remaining_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.invoiced_type_id="{ item }">
          {{ item.invoiced_type }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.billed_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ value }">
          <rs-table-cell-string limit="20" :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters(["clusterId", "invoicedTypeList", "projects"]),
  },
  data() {
    return {
      selectedBudget: null,
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Yıl",
          value: "year",
          searchable: "text",
        },
        {
          text: "Ay",
          value: "month",
          searchable: "text",
        },
        {
          text: "Fatura Tipi",
          value: "invoiced_type_id",
          searchable: "multiselect",
          options: () => this.invoicedTypeList,
        },
        {
          text: "Tedarikçi",
          value: "provider",
          searchable: "text",
        },
        {
          text: "Gider Türü",
          value: "expense_type",
          searchable: "text",
        },
        {
          text: "Gider Tipi",
          value: "child_expense_type",
          searchable: "text",
        },
        {
          text: "Belge No",
          value: "invoice_no",
          searchable: "text",
        },
        {
          text: "Tarih",
          value: "billed_on",
          searchable: "date",
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Proje",
          value: "project",
          searchable: "select",
          options: () => this.projects,
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Kalan Tutar",
          value: "remaining_amount",
          searchable: "number",
          align: "end",
        },
      ],
      options: {
        sortBy: [],
        sortDesc: [],
      },
      search: {
        billed_on: {
          min: undefined,
          max: undefined,
        },
      },
      titleBarAttrs: {
        exportUrl: "expense/invoice-items",
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = [this.clusterId];
      params.budget_id_open = this.selectedBudget;

      return params;
    },
    clearFilters() {
      this.$refs.headSearch.reset();
      this.filterdate = null;
      this.search.query = "";
      this.search.billed_on = {
        min: undefined,
        max: undefined,
      };
      this.loadList();
    },
    loadList() {
      if (this.isLoading || !this.clusterId || !this.selectedBudget) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("expense/invoice-items", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick(item) {
      if (!item.invoice_id) {
        item = this.selectedItems[0];
      }

      if (this.can("edit-invoice")) {
        this.$router.push({
          name: "expenses.invoices.edit",
          params: { id: item.invoice_id },
        });
      } else {
        this.$router.push({
          name: "expenses.invoices.show",
          params: { id: item.invoice_id },
        });
      }
    },
    handleBudgetChange(selectedBudget) {
      this.selectedBudget = selectedBudget;

      this.loadList();
    },
    handleFormSaved() {
      this.loadList();
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
};
</script>
